
// lib
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'

// app
import Brand from '@/app/shared/models/Brand'
import BrandService from '@/app/shared/services/BrandService'
import UiFormGroup from '@/app/shared/components/ui-form-group.vue'
import ConstructorComponent from '@/app/constructor/shared/models/ConstructorComponent'
import DataMixin from '@/app/shared/mixins/DataMixin'
import isEqual from 'lodash-es/isEqual'
import ProductService from '@/app/shared/services/ProductService'

@Component({
  components: {
    UiFormGroup
  }
})
export default class AppBrandSlider extends Mixins<DataMixin<Brand>>(DataMixin) {
  @Prop({ type: ConstructorComponent }) readonly value!: ConstructorComponent

  loading = false
  items: Brand[] = []
  search: string | null = null
  select: string | null = null
  updated = false

  get idsProp (): number[] {
    return this.value.attrs.list as number[] | undefined ?? []
  }

  @Watch('search')
  async handleSearchProduct (value: string): Promise<void> {
    if (value && value !== this.select) {
      this.loading = true
      this.items = await BrandService.searchByName(value)
      this.loading = false
    }
  }

  @Watch('select')
  handleSelectBrandId (value: Brand): void {
    this.dataMixin_data.push(value)
  }

  @Watch('dataMixin_data')
  emitData (value: Brand[]): void {
    this.$emit('input', new ConstructorComponent({
      ...this.value,
      $uuid: this.value.$uuid,
      attrs: {
        list: value.map(v => v.id)
      }
    }))
  }

  @Watch('idsProp', { immediate: true })
  async initialProducts (value: number[], oldValue: number[]): Promise<void> {
    if (isEqual(value, oldValue) || this.updated) {
      return
    }

    this.updated = true

    this.dataMixin_data = await BrandService.fetchListByIds(value)
  }
}
