import Model from '@/app/shared/models/Model'

export interface IBrand {
  id: number
  link: string
  image: string
  name: string
}

export default class Brand extends Model<IBrand> implements IBrand {
  id = 0
  link = ''
  image = ''
  name = ''

  constructor (props: IBrand) {
    super(props)

    if (props) {
      Object.assign(this, props)
    }
  }
}
