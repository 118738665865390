// app
import Brand, { IBrand } from '@/app/shared/models/Brand'

export default class BrandService {
  private static data: IBrand[] = [{
    id: 1,
    link: 'https://diam-almaz.ru/proizvoditeli/ammann/',
    image: 'https://diam-almaz.ru/img/vendors/ammann.jpg',
    name: 'Ammann'
  }, {
    id: 2,
    link: 'https://diam-almaz.ru/proizvoditeli/schwamborn/',
    image: 'https://diam-almaz.ru/img/vendors/schwamborn.jpg',
    name: 'Schwamborn'
  }, {
    id: 3,
    link: 'https://diam-almaz.ru/proizvoditeli/tcm/',
    image: 'https://diam-almaz.ru/img/files/proizvoditeli/tcm-logo.jpg',
    name: 'TCM'
  }, {
    id: 4,
    link: 'https://diam-almaz.ru/proizvoditeli/sdlg/',
    image: 'https://diam-almaz.ru/img/files/proizvoditeli/sdlg-logo.jpg',
    name: 'SDLG'
  }, {
    id: 5,
    link: 'https://diam-almaz.ru/proizvoditeli/tss/',
    image: 'https://diam-almaz.ru/img/vendors/tss.jpg',
    name: 'ТСС'
  }, {
    id: 6,
    link: 'https://diam-almaz.ru/proizvoditeli/lissmac/',
    image: 'https://diam-almaz.ru/img/vendors/lissmac.jpg',
    name: 'Lissmac'
  }, {
    id: 7,
    link: 'https://diam-almaz.ru/proizvoditeli/karcher/',
    image: 'https://diam-almaz.ru/img/files/proizvoditeli/karcher-logo.jpg',
    name: 'Karcher'
  }, {
    id: 8,
    link: 'https://diam-almaz.ru/proizvoditeli/atlas-copco/',
    image: 'https://diam-almaz.ru/img/files/proizvoditeli/atlas-copco-logo.jpg',
    name: 'Atlas Copco'
  }]

  static async searchByName (name: string): Promise<Brand[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const responseData = this.data.filter(v => v.name.toLowerCase().includes(name.toLowerCase()))
        resolve(responseData.map(v => new Brand(v)))
      }, 1000)
    })
  }

  static async fetchListByIds (ids: number[]): Promise<Brand[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const responseData = this.data.filter(v => ids.includes(v.id))
        resolve(responseData.map(v => new Brand(v)))
      })
    })
  }
}
